import React from 'react'
import { Container, Image, Grid, Table, Header, List } from 'semantic-ui-react'
import Obfuscate from 'react-obfuscate'

import Layout from '../components/layout'
import {
  LayoutSection,
  primaryLayoutColor,
  secondaryLayoutColor,
  RightParagraph,
  BlackLink,
} from '../components/layout-components'

import RonaldImg from '../images/Ronald-Steinke.jpg'

const AboutMePage = () => (
  <Layout>
    <LayoutSection color={primaryLayoutColor} header={<h1>Lebenslauf</h1>} text>
      <Grid padded container>
        <Grid.Row>
          <Grid.Column width={4} only="tablet computer">
            <Header size="tiny">Dipl.-Ingenieur</Header>
          </Grid.Column>
          <Grid.Column computer={12} tablet={12} mobile={16}>
            <Container fluid>
              <Grid>
                <Grid.Row only="mobile">
                  <Grid.Column mobile={8}>
                    <Header size="tiny">Dipl.-Ingenieur</Header>
                  </Grid.Column>
                  <Grid.Column mobile={8}>
                    <Image src={RonaldImg} rounded size="large" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column computer={10} tablet={10} mobile={16}>
                    <Table basic="very" collapsing unstackable definition>
                      <Table.Body>
                        {[
                          ['Wohnort:', 'Eldenaer Str. 6, 10247 Berlin'],
                          [
                            'Telefon:',
                            <Obfuscate element="p" tel="0174 / 25 40 428" />,
                          ],
                          [
                            'E-Mail:',
                            <Obfuscate
                              element="p"
                              email="steinke.ronald@gmail.com"
                            />,
                          ],
                          ['Geburtsdatum:', '06.10.1983'],
                          ['Geburtsort:', 'Stendal'],
                        ].map((row, i) => {
                          return row.length ? (
                            <Table.Row key={i}>
                              <Table.Cell>{row[0]}</Table.Cell>
                              <Table.Cell>{row[1]}</Table.Cell>
                            </Table.Row>
                          ) : null
                        })}
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                  <Grid.Column computer={6} tablet={6} only="tablet computer">
                    <Image src={RonaldImg} rounded size="large" />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </LayoutSection>

    <LayoutSection
      color={secondaryLayoutColor}
      header={<h3>Berufliche Erfahrung</h3>}
      text
    >
      <Grid padded container>
        <Grid.Row>
          {/* Selbst */}
          <Grid.Column width={4} only="computer tablet">
            <Header size="tiny">
              <p>07/2019 – Jetzt</p>
            </Header>
          </Grid.Column>
          <Grid.Column width={2} only="mobile" verticalAlign="top">
            <Header size="tiny" textAlign="center">
              <p>
                Ab
                <br />
                07
                <br />
                19
              </p>
            </Header>
          </Grid.Column>
          <Grid.Column computer={12} tablet={12} mobile={14}>
            <Header size="tiny">
              Freiberufler - T&auml;tigkeit als Softwareentwickler
            </Header>
            <List bulleted>
              <List.Item>Backend- und Plattformentwicklung</List.Item>
              <List.Item>IoT/M2M-Datenverarbeitung</List.Item>
            </List>
            <Header size="tiny" sub>
              Projekte
            </Header>
            <List>
              <List.Item>
                Dashboard f&uuml;r Produktions&shy;&uuml;berwachung (03/20 –
                04/20)
              </List.Item>
              <List.Item>
                Evaluierung des oneM2M-Standards durch Prototyp (01/20 – 02/20)
              </List.Item>
            </List>
            <RightParagraph>
              <BlackLink to="/projects">&Uuml;bersicht &hellip;</BlackLink>
            </RightParagraph>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          {/* FOKUS */}
          <Grid.Column width={4} only="computer tablet">
            <Header size="tiny">
              <p>07/2015 – 06/2019</p>
            </Header>
          </Grid.Column>
          <Grid.Column width={2} only="mobile" verticalAlign="top">
            <Header size="tiny" textAlign="center">
              <p>
                07
                <br />
                15 &mdash; 06
                <br />
                19
              </p>
            </Header>
          </Grid.Column>
          <Grid.Column computer={12} tablet={12} mobile={14}>
            <Header size="tiny">Wissenschaftlicher Mitarbeiter</Header>
            Fraunhofer-Institut f&uuml;r Offene Kommunikationssysteme FOKUS,
            Software-based Networks (NGNI)
            <Header size="tiny" sub>
              T&auml;tigkeiten:
            </Header>
            <List bulleted>
              <List.Item>Technische Leitung des Edge Computing Teams</List.Item>
              <List.Item>
                OSS Release von{' '}
                <a href="https://github.com/OpenMTC/OpenMTC">OpenMTC</a>
              </List.Item>
              <List.Item>
                Bearbeitung von nationalen und internationalen Projekten:
                <List.List>
                  <List.Item>
                    FIESTA (Datenmangement von IoT-Testbeds)
                  </List.Item>
                  <List.Item>
                    SmartOrchestra (Smarte Dienste in Facility Management)
                  </List.Item>
                  <List.Item>
                    SiNSeWa (Sichere Netze f&uuml;r Wartung)
                  </List.Item>
                </List.List>
              </List.Item>
              <List.Item>Umsetzung verschiedener Demonstratoren</List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          {/* TUB */}
          <Grid.Column width={4} only="computer tablet">
            <Header size="tiny">
              <p>07/2014 – 06/2015</p>
            </Header>
          </Grid.Column>
          <Grid.Column width={2} only="mobile" verticalAlign="top">
            <Header size="tiny" textAlign="center">
              <p>
                07
                <br />
                14 &mdash; 06
                <br />
                15
              </p>
            </Header>
          </Grid.Column>
          <Grid.Column computer={12} tablet={12} mobile={14}>
            <Header size="tiny">Wissenschaftlicher Mitarbeiter</Header>
            TU Berlin, Architektur der Vermittlungsknoten (AV)
            <Header size="tiny" sub>
              T&auml;tigkeiten:
            </Header>
            <List bulleted>
              <List.Item>
                Entwicklung an der{' '}
                <a href="https://www.openmtc.org">oneM2M-Implementierung</a>
              </List.Item>
              <List.Item>
                Bearbeitung von Projekten: TRESCIMO (Smart City / Smart Grid)
              </List.Item>
              <List.Item>
                Mitarbeit in der Lehre: Seminare, Projekte, Vorlesungen
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </LayoutSection>

    <LayoutSection color={primaryLayoutColor} header={<h3>Ausbildung</h3>} text>
      <Grid padded container>
        <Grid.Row>
          {/* TUB */}
          <Grid.Column width={4} only="computer tablet">
            <Header size="tiny">
              <p>10/2005 – 06/2014</p>
            </Header>
          </Grid.Column>
          <Grid.Column width={2} only="mobile" verticalAlign="top">
            <Header size="tiny" textAlign="center">
              <p>
                10
                <br />
                05 &mdash; 06
                <br />
                14
              </p>
            </Header>
          </Grid.Column>
          <Grid.Column computer={12} tablet={12} mobile={14}>
            <p>TU Berlin, 10623 Berlin</p>
            <p>
              Diplom-Ingenieur der Technische Informatik
              <br />
              Schwerpunkt: Netzwerktechnologien
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </LayoutSection>

    <LayoutSection
      color={secondaryLayoutColor}
      header={<h3>Weitere Kenntnisse</h3>}
      text
    >
      <Grid padded container>
        <Grid.Column width={4} only="tablet computer" />
        <Grid.Column computer={12} tablet={12} mobile={16}>
          <Table basic="very" collapsing size="small" unstackable definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Sprachen</Table.Cell>
                <Table.Cell>
                  Deutsch (Muttersprache)
                  <br />
                  Englisch (verhandlungssicher)
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>F&uuml;hrerschein</Table.Cell>
                <Table.Cell>B</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Hobbys</Table.Cell>
                <Table.Cell>Handball, Volleyball</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
    </LayoutSection>
  </Layout>
)

export default AboutMePage
